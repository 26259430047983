import { Flex } from "@chakra-ui/react";
import useScrollToTop from "hooks/useScrollToTop";
import Footer from "pages/HomePage/Footer/Footer";
import Navbar from "pages/HomePage/Navbar/Navbar";
import React from "react";
import { Helmet } from "react-helmet";
import Logo from "assets/img/sellerdash.png";
import "./HomeLayout.scss"
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { SUPPORT_PHONE } from "constants/routes";
import NewsStrip from "components/Layouts/NewsStrip/NewsStrip";
import { REACT_APP_FE_URL } from "constants/constant";

interface Props {
    children: React.ReactNode;
    title: string;
    navHide?: boolean
}

const HomeLayout: React.FC<Props> = ({ children, title }) => {
    useScrollToTop()

    return(
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Find sales of sellers and track performance of their best selling products with sellerdash which is a best daraz product hunting tool." />
                <link rel="canonical" href={REACT_APP_FE_URL} />
                <meta property="og:title" content={title} />
                <meta property="og:url" content={REACT_APP_FE_URL} />
                <meta property="og:locale" content="en" />
                <meta property="og:site_name" content="Sellerdash" />
                <meta property="og:description" content="Find sales of sellers and track performance of their best selling products with sellerdash which is a best daraz product hunting tool." />
                <meta property="og:type" content="website" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta property="og:image" content={REACT_APP_FE_URL+Logo} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="600" />
                <meta property="og:image:type" content="image/png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image:src" content={REACT_APP_FE_URL+Logo} />
                <meta name="twitter:image:width" content="1200" />
                <meta name="twitter:image:height" content="600" />
            </Helmet>
            <Flex className="wrapper">
                <Flex className="inner-wrapper">
                    <NewsStrip />
                    <Navbar hide={true} />
                        {children}
                    <Footer />
                </Flex>
            </Flex>
            <FloatingWhatsApp
                phoneNumber={SUPPORT_PHONE}
                accountName="Support"
                avatar={Logo}
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </>
    )
};

export default HomeLayout