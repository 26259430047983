import { useEffect, useState } from "react";
import { useAppContext } from "hooks/app";
import { Modals } from "constants/modals";
import ModalLayout from "components/ModalLayout/ModalLayout";
import { useLocation } from "react-router-dom";
import { useLogout, useUser } from "hooks/user";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import PhoneVerification from "./PhoneVerification/PhoneVerification";
import { isValidPhoneNumber } from "react-phone-number-input";
import { usePhoneVerify } from "hooks/user";
import toast from "react-hot-toast";
import { WHATSAPP_LINK } from "constants/routes";

export const BarePhoneVerificationComponent = () => {
  const { openModal } = useAppContext();
  const { logout } = useLogout();

  const [showOtp, setShowOtp] = useState(false);
  const { pathname } = useLocation();
  const { user, isLoading } = useUser();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const { sendWhatsappOtp } = usePhoneVerify();

  const [isRequestLoading, setIsRequestLoading] = useState(false)
  useEffect(() => {
    if (isLoading) return;
    if (!user?.phoneNumber && pathname.includes("user")) {
      openModal?.(Modals.PHONE_POPUP);
    }
  }, [user]);

  const handleSendOtp = async () => {
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));

    if (!isValidPhoneNumber(phoneNumber)) return;
    setIsRequestLoading(true)
    await sendWhatsappOtp({ id: user?.id, phone: phoneNumber }).then((data) => {
      if(data.success) {
        setShowOtp(true);
      } else {
        toast.error(data.message)
      }
      setIsRequestLoading(false)
    });
  };

  const handleNumberChange = (number: string) => {
    isValidPhoneNumber(number) && setIsPhoneValid(true);

    setPhoneNumber(number);
  };

  return (
    <div className="min-h-[400px] flex flex-col justify-between">
      <div className="flex flex-col items-start justify-start gap-4">
        <p>You're logged in with <b>{user?.email}</b></p>
        {!showOtp ? (
          <>
            <div>
              <h3 className="text-xl font-bold">Verify your WhatsApp</h3>
              <p>Insert WhatsApp number to verify</p>
            </div>
            <div className="flex">
              <PhoneInput
                defaultCountry="pk"
                value={phoneNumber}
                onChange={(phoneNumber) => handleNumberChange(phoneNumber)}
                style={isPhoneValid ? {
                } : { border: "1px solid red", borderRadius: '5px' }}
                inputStyle={{
                }}
              />
              {!isPhoneValid && (
                <p style={{ color: "red", fontSize: '12px' }}>Enter a valid number</p>
              )}
            </div>
              
            <button className="btnPrimary" disabled={isRequestLoading} onClick={handleSendOtp}>
              Verify Now
            </button>
          </>
        ) : (
          <PhoneVerification
            onBack={() => setShowOtp(false)}
            phone={phoneNumber}
          />
        )}
        <div>
          <p>Didn't receive OTP? Contact to our Support team via WhatsApp <a className="text-primary" href={`${WHATSAPP_LINK}`} target="_blank">+92 317 4466178</a> </p>
        </div>
      </div>
      <button onClick={() => logout().catch()} className="btn btnSecondary">Logout</button>
      </div>
  )
}

const PhonePopup = () => {
  

  return (
    <ModalLayout modal={Modals.PHONE_POPUP}>
      <BarePhoneVerificationComponent />    
    </ModalLayout>
  );
};
export default PhonePopup;
