import axios from "axios";
import { Apis } from "constants/apis";
import { TGetStripeInvoiceService } from "types/services";
import { decrypt } from "utils/security";
import { getToken } from "utils/token";

const getStripeInvoice: TGetStripeInvoiceService = async () => {
    const { data } = await axios.get(Apis.GET_STRIPE_INVOICE, { headers: { Authorization: getToken() } })
    return data;
}

export default getStripeInvoice