import React from "react";
import { Button, Image } from "antd";
import "./styles.scss";
import StarRatingIcon from "assets/icons/StarRatingIcon";
import { IStoreDiscovery } from "types/services";

interface IStoreCard {
    store: IStoreDiscovery
    handleQuickView: (itemId: number) => void
    storeUrl: string
}
const StoreCard: React.FC<IStoreCard> = ({store, handleQuickView, storeUrl }) => {
  const handleRedirect = () => {
     let url = ''
    if(storeUrl.indexOf('https') == -1)
      url = 'https://' + storeUrl
    else
      url = storeUrl
    window.open(url, "_blank")
  }
  return (
    <div className="store-card">
      <div className="image-wrapper">
        <Image
          src={store.brandLogo}
          width={"100%"}
          height={"100%"}
          alt={"image"}
          className="store-image"
          preview={false}
        />
      </div>
      <div className="store-details">
        <div className="store-details-content">
          <div>
            <div>
              <h5 className="store-title">{store.brandName}</h5>
            </div>
            <div className="price-container">
              <div className="stat-item">
                  <StarRatingIcon />
                  {store.rating} ({store.reviews.toLocaleString()})
              </div>
            </div>
            <div>
              Total Active Products: {store.totalProducts.toLocaleString()}
            </div>
          </div>
          <div className="sold-units-container">
            <div className="sold-units">
              Sold Units:
            </div>
            <div className="stats-container">
              <div className="stat-item">
                <span className="sale-count">{store.weeklySale.toLocaleString()}</span>
                <span>Weekly</span>
              </div>
              <div className="stat-item">
                <span className="sale-count">{store.monthlySale.toLocaleString()}</span>
                <span>Monthly</span>
              </div>
              <div className="stat-item">
                <span className="sale-count">{store.allTimeSale.toLocaleString()}</span>
                <span>All Time</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="store-quick-view-wrap">
          <div className="action-btns">
            <Button className="primary-btn reset-btn" onClick={() => handleQuickView(store.shopId)}>
              Quick View
            </Button>
            <Button className="secondary-btn reset-btn" onClick={() => handleRedirect()}>
              Store Details
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
