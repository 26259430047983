import { useEffect, } from "react";
import { useAppContext } from "hooks/app";
import { Modals } from "constants/modals";
import DetailImg from "assets/svgs/productDetails.svg"
import ModalLayout from "components/ModalLayout/ModalLayout";
import { Flex, Text, Image,  useMediaQuery } from "@chakra-ui/react";

import * as styles from "./styles"
import { Link, useLocation } from "react-router-dom";
import { CHROME_EXTENSION_LINK } from "constants/routes";
import { useExtensionInstallationUpdate } from "hooks/user";
import { REACT_APP_EXTENSION_ID } from "constants/constant";
const InstallPopup = () => {
    const { openModal } = useAppContext()
    const { pathname } = useLocation()
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
    const { updateExtensionStatus } = useExtensionInstallationUpdate()
    useEffect(() => {
        const customImg = document.createElement("img")
        customImg.src = "chrome-extension://" + REACT_APP_EXTENSION_ID + "/src/assets/loader.gif";
        customImg.onload = function() {
            updateExtensionStatus({ isInstalled: true })
        }; 
        customImg.onerror = function() {
            updateExtensionStatus({ isInstalled: false })
            if(pathname.includes("/user")) {
                openModal?.(Modals.INSTALL_POPUP)
            }
        }; 
    }, [])

    return (
        <ModalLayout modal={Modals.INSTALL_POPUP}>
            <Flex {...styles.ModalWrapper} w={isLargerThan800 ? "800px" : "100%"} minH={isLargerThan600 ? "581px" : "420px"} py={isLargerThan600 ? "25px" : "15px"} px={isLargerThan600 ? "35px" : "20px"}>
                <Image src={DetailImg} alt="detail" w={isLargerThan800 ? "80%" : "90%"} />
                <Text {...styles.TextOne} fontSize={isLargerThan600 ? "20px" : "18px"} textAlign="center">You have to install Sellerdash extension</Text>
                <Text {...styles.TextTwo} textAlign="center">Extension will help you analyze any products on Daraz marketplaces</Text>
                <Link to={CHROME_EXTENSION_LINK} className="btnPrimary" target="_blank">Download Now</Link>
            </Flex>
        </ModalLayout>
    )
}
export default InstallPopup